import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FaleComAGente from "../components/fale-com-a-gente"

import ImageTwo from "../components/Estudio/ImageTwo"
import ImageThree from "../components/Estudio/ImageThree"
import ImageFour from "../components/Estudio/ImageFour"
import ImageFive from "../components/Estudio/ImageFive"
import ImageSix from "../components/Estudio/ImageSix"

// import YoutubeVideo from "../components/Estudio/YoutubeVideo"

const StudioPage = ({ location }) => (
  <Layout darkmode={true} location={location}>
    <SEO title="Estúdio" />
    <Container>
      <SectionTwo>
        <h3>estúdio</h3>
        <h2>Conheça o espaço</h2>
        <p>
          Um espaço versátil e totalmente moldável à sua necessidade: esse é o
          estúdio da Zoombe Filmes. Equipada com monitores de qualidade 4K,
          iluminação em LED, climatização, isolamento acústico e mobiliário
          personalizado, a estrutura se adequa perfeitamente a produções
          jornalísticas, de entretenimento, atividades físicas, entrevistas e
          outros formatos. Com equipamentos de última geração, a Zoombe Filmes
          entregará ao seu negócio os melhores resultados em audiovisual.
        </p>
      </SectionTwo>
      <SectionImageLeft>
        <ImageTwo className="gatsby-image-wrapper" />
      </SectionImageLeft>
      <SectionImageRight>
        <ImageThree className="gatsby-image-wrapper" />
      </SectionImageRight>

      <SectionImageLeft>
        <ImageFour className="gatsby-image-wrapper" />
      </SectionImageLeft>

      <SectionImageRight>
        <ImageFive className="gatsby-image-wrapper" />
      </SectionImageRight>
      <SectionImageLeft style={{ marginBottom: "50px" }}>
        <ImageSix className="gatsby-image-wrapper" />
      </SectionImageLeft>
      <FaleComAGente />
    </Container>
  </Layout>
)

const Container = styled.div`
  width: 100%;
  height: fit-content;
  background-color: #212121;
`

// const SectionOne = styled.section`
//   max-width: 1200px;
//   margin: 0 auto;

//   display: flex;
//   justify-content: center;
//   align-items: flex-start;

//   height: fit-content;

//   .gatsby-image-wrapper {
//     width: 900px;
//   }
// `

const SectionTwo = styled.section`
  padding-top: 50px;
  padding-left: 150px;
  max-width: 1200px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h2,
  h3,
  p {
    color: white;
    font-weight: 400;
  }

  h3 {
    color: #f44336;
    margin-left: 20px;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 50px;
  }

  p {
    max-width: 700px;
    margin-top: 20px;
    margin-left: 100px;
  }

  @media (max-width: 990px) {
    padding-left: 30px;
    p {
      max-width: 95%;
      margin-top: 20px;
      margin-left: 0px;
    }
  }

  @media (max-width: 560px) {
    h2 {
      font-size: 40px;
    }

    p {
      padding-right: 20px;
    }

    h3 {
      margin-left: 0px;
      margin-bottom: 5px;
    }
  }
`

const SectionImageLeft = styled.section`
  padding-top: 50px;
  max-width: 1200px;
  margin: 0 auto;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  height: fit-content;

  .gatsby-image-wrapper {
    width: 900px;
  }
`

const SectionImageRight = styled.section`
  padding-top: 50px;
  max-width: 1200px;
  margin: 0 auto;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  height: fit-content;

  .gatsby-image-wrapper {
    width: 900px;
  }
`

export default StudioPage
